<template>
  <ion-card class="my-corner-card p-3 m-0">
    <div class="d-flex flex-column">
      <div class="sub-title mt-2 text-black">My Corner:</div>

      <div class="d-flex py-2" style="overflow: unset">
        <ion-button class="btn mr-2" style="overflow: unset" @click="goToSpaces">
          <ion-icon class="mr-2" :icon="peopleOutline" /><span v-if="!isMobSmallScreen">Spaces I Joined </span>
          <ion-badge v-if="unReadCounts" class="position-absolute top" color="danger">
            <span v-if="unReadCounts >= 99">99++</span>
            <span v-else>{{ unReadCounts }}</span>
          </ion-badge>
        </ion-button>
      </div>
    </div>
  </ion-card>
</template>

<script lang="ts" setup>
import { peopleOutline } from 'ionicons/icons';


const props = defineProps({
  unReadCounts: {
    type: Number,
    default: 0,
  },
});

const router = useRouter();

const goToSpaces = () => {
  router.push({ name: 'user-spaces' });
};

const { width } = useWindowSize();

const isMobSmallScreen = computed(() => {
  return width.value <= 500;
});
</script>

<style lang="sass" scoped>
.top
  top: -8px
  margin-left: calc(100% - 24px)
  border-radius: 10px
  font-size: 12px
  width: 45px

.btn
  width: 100%
  height: 59px
::v-deep
  .button
    --overflow: unset !important
    --background: #00b4c5
    --border-radius: 10px

.info-content
  max-width: 557px
  .key-info
    font-size: 14px
    background: white
    border-radius: 7px
.dark .key-info
  background: black

.social-btn
  color: #214163
  font-size: 14px
  font-weight: bold
  width: 157px
  height: 33px
  text-transform: none
  @media(max-width: 500px)
    width: 145px
  @media(max-width: 300px)
    width: 108px

.title
  font-size: 24px
  font-weight: bold
.sub-title
  font-size: 20px
  font-weight: bold
.social-card
  overflow: unset
  background: #cdcfd9
.my-corner-card
  background: white
  overflow: unset
</style>
