<template>
  <transition>
    <div>
      <div class="title" v-if="sectionTitle">{{ sectionTitle }}</div>
      <Grid v-if="!isLoading" :lg="2" :md="2" :sm="1" :scrollSm="false">
        <SocialSpaceCard
          class="grid-item"
          v-for="(space, index) of socialSpaces"
          :key="index"
          :index="index"
          :socialSpace="space"
          :member-count="get(memberCounts, `${get(space, 'id')}.member_count`) || 0"
          :room-count="get(roomCounts, `${get(space, 'id')}.room_count`) || 0"
          :vertical="isDesktopSize"
        />
      </Grid>
      <span class="d-flex flex-wrap" v-if="isLoading">
        <ion-skeleton-text v-for="(item, index) of [0, 1, 2, 3, 4, 5]" :key="index" animated class="spaces ml-1" />
      </span>

      <div class="d-flex align-items-center">
        <back-to-top v-if="toTop" class="d-flex align-self-start" />
        <router-link v-if="seeAllLink" :to="seeAllLink" class="ml-auto mr-0 see-all-text"><b>See all</b></router-link>
      </div>
    </div>
  </transition>
</template>

<script lang="ts" setup>
import Grid from '@/shared/components/storage/Grid.vue';
import SocialSpaceCard from '@/shared/components/storage/SocialSpaceCard.vue';
import BackToTop from '@/shared/components/BackToTop.vue';
import { SocialSpace } from '@/shared/types/static-types';


const props = defineProps({
  sectionTitle: {
    type: String,
    default: '',
  },
  toTop: {
    type: Boolean,
    default: false,
  },
  socialSpaces: {
    type: Array,
    default: [],
  },
  seeAllLink: {
    type: Object,
  },
  memberCounts: {
    type: Object,
    default: {},
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  roomCounts: {
    type: Object,
    default: {},
  },
});

const socialSpaces = toRef(props, 'socialSpaces') as Ref<SocialSpace[]>;
const emits = defineEmits(['reaction']);
const { isDesktopSize } = useWindowSize();
</script>

<style lang="sass" scoped>
.spaces
  width: 250px
  height: 155px
.chat
  font-weight: bold
.social-spaces
  overflow-x: auto
.name
  font-size: 18px
  font-weight: bold
  cursor: pointer
  word-break: break-word
  margin: auto 0.25rem
  .name:hover
    opacity: 0.7
.see-all-text
  color: #214163
  display: inline-block
.title
  font-size: 24px
  font-weight: bold
.social-space-card
  overflow: unset
  width: calc(33% - 18px)
  min-width: 200px !important
  text-break: break-word
  border-radius: 10px
  img
    border-top-left-radius: 10px
    border-top-right-radius: 10px

.img
  width: 250px
  height: 130px
  object-fit: cover
.social-space-list
  overflow-x: hidden
  -ms-overflow-style: none
  scrollbar-width: none
  &::-webkit-scrollbar
    display: none
.card-container
  margin: 0 auto
  justify-content: center
@media(max-width: 500px)
  .social-space-card
    width: calc(50% - 18px)
    min-width: 100px !important
  img
    height: auto !important
    aspect-ratio: 1/1
    object-fit: cover
</style>
