<template>
  <ion-page class="page bg-transparent">
    <div id="page-header" class="d-flex justify-content-between align-items-center flex-wrap py-3">
      <div class="title">Social Spaces</div>

      <div class="ml-auto ml-2">
        <ion-button v-if="get(user, 'is_email_verified', true)" class="text-white social-btn" @click="createSocialSpace"
          >New Social Space</ion-button
        >
        <VerificationButton actionName="New Social Space" v-else />
      </div>
    </div>

    <my-corner-box :un-read-counts="totalUnreadCounts" />

    <div class="top-nav my-4">
      <div class="top-nav-btn clickable-item-hov" @click="scrollTo('trending')">
        <span>Trending </span>
      </div>
      <div class="top-nav-btn clickable-item-hov" @click="scrollTo('top')">
        <span>Top</span>
      </div>
      <div class="top-nav-btn clickable-item-hov" @click="scrollTo('latest')">
        <span>Latest</span>
      </div>
    </div>

    <top-banner banner-name="socialSpaceHomeHeader" class="py-2" />

    <home-vertical-chatrooms
      id="trending"
      class="mt-3"
      section-title="Trending Chatrooms"
      :to-top="true"
      :chatrooms="trendingchatrooms"
      :ischat-loading="chatTrendLoading"
      :counts="trendingCounts"
      :see-all-link="{ name: 'social-space-chatroom-trending' }"
    />

    <horizontal-spaces
      id="trending"
      section-title="Trending Spaces"
      class="mt-5"
      :is-loading="isLoading"
      :to-top="true"
      :member-counts="memberCounts"
      :social-spaces="trendingSocialSpaces"
      :room-counts="trendingRoomCounts"
      :see-all-link="{ name: 'social-space-trending' }"
      @reaction="onTrendingReaction"
    />

    <transition>
      <horizontal-spaces
        id="top"
        class="mt-5"
        section-title="Top Spaces"
        :is-loading="isTopLoading"
        :to-top="true"
        :member-counts="topMemberCounts"
        :social-spaces="topSocialSpaces"
        :room-counts="topRoomCounts"
        :see-all-link="{ name: 'social-space-top' }"
        @reaction="onTopReaction"
      />
    </transition>
    <transition>
      <home-vertical-chatrooms
        class="mt-5"
        id="top"
        section-title="Top Chatrooms"
        :to-top="true"
        :ischat-loading="chatTopLoading"
        :chatrooms="topchatrooms"
        :counts="topCounts"
        :see-all-link="{ name: 'social-space-chatroom-top' }"
      />
    </transition>

    <transition>
      <horizontal-spaces
        class="mt-5"
        id="latest"
        section-title="Latest Spaces"
        :to-top="true"
        :is-loading="isLatestLoading"
        :member-counts="latestMemberCounts"
        :social-spaces="latestSocialSpaces"
        :room-counts="latestRoomCounts"
        :see-all-link="{ name: 'social-space-latest' }"
        @reaction="onlatestReaction"
      />
    </transition>
    <transition>
      <home-vertical-chatrooms
        class="mt-5 mb-3"
        id="latest"
        section-title="Latest Chatrooms"
        :to-top="true"
        :ischat-loading="ischatLoading"
        :chatrooms="latestchatrooms"
        :counts="latestCounts"
        :see-all-link="{ name: 'social-space-chatroom-latest' }"
      />
    </transition>
  </ion-page>
</template>

<script lang="ts" setup>
import HorizontalSpaces from './components/HorizontalSpaces.vue';
import HomeVerticalChatrooms from './components/HomeVerticalChatrooms.vue';
import MyCornerBox from './components/MyCornerBox.vue';
import { sendAnalyticsEvent } from '@/shared/services/analytics';
import TopBanner from '@/shared/pages/home/components/TopBanner.vue';
import { getSocialSpaceRoomsCount, getTopWorlds, getWorldListings, getWorldsMemberCounts } from '@/shared/actions/worlds';
import { getChatroomCounts, getJoinedChatroomCounts, getTopChatrooms } from '@/shared/actions/socialSpaceChatroom';
import { SocialSpace } from '@/shared/types/static-types';
import VerificationButton from '@/shared/components/VerificationButton.vue';
import { authStore } from '@/shared/pinia-store/auth';

const { user } = authStore();
const router = useRouter();
const trendingSocialSpaces = ref([]) as any;
const topSocialSpaces = ref([]) as any;
const latestSocialSpaces = ref([]) as any;
const trendingchatrooms = ref([]);
const topchatrooms = ref([]);
const latestchatrooms = ref([]);
const memberCounts = ref({});
const isLoading = ref(false);
const isTopLoading = ref(false);
const isLatestLoading = ref(false);
const ischatLoading = ref(true);
const latestMemberCounts = ref({});
const topMemberCounts = ref({});
const trendingRoomCounts = ref({});
const topRoomCounts = ref({});
const latestRoomCounts = ref({});
const trendingCounts = ref({});
const topCounts = ref({});
const latestCounts = ref({});
const totalUnreadCounts = ref(0);
const chatTrendLoading = ref(false);
const chatTopLoading = ref(false);
const { isDesktopSize } = useWindowSize();
const { isChromeBrowser } = useBrowserName();

const createSocialSpace = () => {
  router.push({ name: 'create-social-space' });
};
const onTrendingReaction = (event: { reactResp: {}; social: SocialSpace }) => {
  const index = trendingSocialSpaces.value.findIndex((char: any) => char.id === event.social.id);
  trendingSocialSpaces.value[index] = { ...event.social, ...get(event.reactResp, 'updatedReactionsData', {}) };
};
const onTopReaction = (event: { reactResp: {}; social: SocialSpace }) => {
  const index = topSocialSpaces.value.findIndex((char: any) => char.id === event.social.id);
  topSocialSpaces.value[index] = { ...event.social, ...get(event.reactResp, 'updatedReactionsData', {}) };
};
const onlatestReaction = (event: { reactResp: {}; social: SocialSpace }) => {
  const index = latestSocialSpaces.value.findIndex((char: any) => char.id === event.social.id);
  latestSocialSpaces.value[index] = { ...event.social, ...get(event.reactResp, 'updatedReactionsData', {}) };
};

const fetchTrendingSocialSpaces = async () => {
  isLoading.value = true;
  const resp = await getTopWorlds(1, 'socialspace', '-hot_score', 6);
  trendingSocialSpaces.value = resp.results;
  fetchTrendingSocialSpacesMemberCounts();
  fetchTrendingSocialSpaceRoomCounts();
  isLoading.value = false;
};
const fetchTopSocialSpaces = async () => {
  isTopLoading.value = true;
  const resp = await getTopWorlds(1, 'socialspace', '-rxn_count', 6);
  topSocialSpaces.value = resp.results;
  fetchTopSocialSpacesMemberCounts();
  fetchTopSocialSpaceRoomCounts();
  isTopLoading.value = false;
};
const fetchLatestSocialSpaces = async () => {
  isLatestLoading.value = true;
  const resp = await getWorldListings(1, 6, 'socialspace');
  latestSocialSpaces.value = resp.results;
  fetchLatestSocialSpacesMemberCounts();
  fetchLatestSocialSpaceRoomCounts();
  isLatestLoading.value = false;
};

const fetchTopPublicChatrooms = async () => {
  chatTopLoading.value = true;
  const resp = await getTopChatrooms('top', 1, 5);
  topchatrooms.value = resp.results;
  fetchTopchatroomCounts();
  chatTopLoading.value = false;
};
const fetchTopchatroomCounts = async () => {
  const resp = await getChatroomCounts(map(topchatrooms.value, 'id') as string[]);
  topCounts.value = keyBy(resp, 'id');
};

const fetchSpaceUnreadCounts = async () => {
  const resp = await getJoinedChatroomCounts();
  totalUnreadCounts.value = resp.count;
};

const fetchTrendingPublicChatrooms = async () => {
  chatTrendLoading.value = true;
  const resp = await getTopChatrooms('trending', 1, 5);
  trendingchatrooms.value = resp.results;
  fetchTrendingchatroomCounts();
  chatTrendLoading.value = false;
};
const fetchTrendingchatroomCounts = async () => {
  const resp = await getChatroomCounts(map(trendingchatrooms.value, 'id') as string[]);
  trendingCounts.value = keyBy(resp, 'id');
};
const fetchlatestPublicChatrooms = async () => {
  ischatLoading.value = true;
  const resp = await getTopChatrooms('-created', 1, 5);
  latestchatrooms.value = resp.results;
  fetchLatestchatroomCounts();
  ischatLoading.value = false;
};

const fetchLatestchatroomCounts = async () => {
  const resp = await getChatroomCounts(map(latestchatrooms.value, 'id') as string[]);
  latestCounts.value = keyBy(resp, 'id');
};

const fetchTrendingSocialSpacesMemberCounts = async () => {
  memberCounts.value = {};
  const resp = await getWorldsMemberCounts(map(trendingSocialSpaces.value, 'id') as string[]);
  memberCounts.value = keyBy(resp, 'world_id');
};
const fetchTopSocialSpacesMemberCounts = async () => {
  topMemberCounts.value = {};
  const resp = await getWorldsMemberCounts(map(topSocialSpaces.value, 'id') as string[]);
  topMemberCounts.value = keyBy(resp, 'world_id');
};

const fetchLatestSocialSpacesMemberCounts = async () => {
  latestMemberCounts.value = {};
  const resp = await getWorldsMemberCounts(map(latestSocialSpaces.value, 'id') as string[]);
  latestMemberCounts.value = keyBy(resp, 'world_id');
};

const fetchTrendingSocialSpaceRoomCounts = async () => {
  const resp = await getSocialSpaceRoomsCount(map(trendingSocialSpaces.value, 'id') as string[]);
  trendingRoomCounts.value = keyBy(resp, 'world_id');
};
const fetchTopSocialSpaceRoomCounts = async () => {
  const resp = await getSocialSpaceRoomsCount(map(topSocialSpaces.value, 'id') as string[]);
  topRoomCounts.value = keyBy(resp, 'world_id');
};
const fetchLatestSocialSpaceRoomCounts = async () => {
  const resp = await getSocialSpaceRoomsCount(map(latestSocialSpaces.value, 'id') as string[]);
  latestRoomCounts.value = keyBy(resp, 'world_id');
};

const scrollTo = (id: string) => {
  try {
    const document = useDocument();
    isDesktopSize.value && isChromeBrowser.value
      ? document.value?.getElementById(id)?.scrollIntoView({ behavior: 'auto', inline: 'center', block: 'nearest' })
      : document.value?.getElementById(id)?.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
  } catch (e) {}
  try {
    sendAnalyticsEvent('Pressed Scroll Shortcut Button on Home', {
      to: id,
    });
  } catch (e) {}
};

onMounted(() => {
  const document = useDocument();
  document.value?.getElementById('page-header')?.scrollIntoView({ behavior: 'auto', block: 'nearest' });

  fetchTrendingSocialSpaces();
  fetchTopSocialSpaces();
  fetchLatestSocialSpaces();
  fetchTopPublicChatrooms();
  fetchTrendingPublicChatrooms();
  fetchlatestPublicChatrooms();
  fetchSpaceUnreadCounts();
});
</script>

<style lang="sass" scoped>
.top-nav
  display: grid
  gap: 15px 6px
  width: calc(135% - 14px) !important
  grid-template-columns: repeat(4, 1fr)
.top-nav-btn
  color: white
  border-radius: 10px
  background-color: #7050B7
  padding: 0.25rem 0.15rem
  height: 35px
  line-height: 25px
  text-align: center
  font-size: 16px
  width: 100%

  span
    user-select: none !important
  @media(max-width: 500px)
    width: 100%
  @media(max-width: 300px)
    width: 81px

.info-content
  max-width: 557px
  .key-info
    font-size: 14px
    background: white
    border-radius: 7px
.social-btn
  color: #214163
  --border-radius: 10px
  font-size: 14px
  font-weight: bold
  width: 157px
  height: 33px
  text-transform: none
  @media(max-width: 500px)
    width: 145px
  @media(max-width: 300px)
    width: 108px

.title
  font-size: 24px
  font-weight: bold
.sub-title
  font-size: 20px
  font-weight: bold
.fan-work-card
  overflow: unset
  background: #cdcfd9
</style>
